<template>
  <div>
    <div class="classify">
      <div class="hot-title">
        <img
          src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/hot.jpg"
        />热门课程
      </div>
      <div
        class="hot-item"
        v-for="item of hotList"
        :key="item.id"
        @click="jumpDetail(item)"
      >
        <div class="tip">
          <!-- is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免-->
          <img :src="$free" v-if="item.is_free == 2" />
          <img :src="$vip" v-else-if="item.is_vip_flat == 1" />
          <img :src="$noVip" v-else />
        </div>
        <van-image
          class="cover"
          :error-icon="$placeImgSmall"
          :loading-icon="$placeImgSmall"
          fit="cover"
          :src="item.cover || $placeImgSmall"
        />
        <div class="detail">
          <p class="title">{{ item.name }}</p>
          <p class="subtitle two-elipsis">{{ item.description }}</p>
        </div>
      </div>
      <van-empty
        style="margin: 0 auto"
        v-if="!hotList.length"
        class="emptySmall"
        description="暂无数据"
      />
    </div>
    <div class="classify" v-if="books.length">
      <div class="hot-title">
        <img
          src="https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/recommend.jpg"
        />推荐书籍
      </div>
      <div class="hot-item" v-for="item of books" :key="item.id" @click="jumpBook(item)">
        <div class="tip">
          <!-- is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免-->
          <img :src="$free" v-if="item.is_free == 2" />
          <img :src="$vip" v-else-if="item.is_vip_flat == 1" />
          <img :src="$noVip" v-else />
        </div>
        <van-image
          class="cover2"
          :error-icon="$placeImgSmall"
          :loading-icon="$placeImgSmall"
          fit="cover"
          :src="item.cover_pc"
        />
        <div class="detail detail2">
          <p class="title">{{ item.name }}</p>
          <p class="subtitle">100人已购买</p>
          <p class="price">
            ￥<b>{{ item.price }}</b
            ><span>￥{{ item.price }}</span>
          </p>
        </div>
      </div>
      <van-empty
        style="margin: 0 auto"
        v-if="!books.length"
        class="emptySmall"
        description="暂无数据"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      height: 0,
      top: 0,
      hotList: [],
      books:[]
    };
  },
  computed: {
    vipInfo() {
      return this.$store.state.vipInfo;
    },
    platName() {
      return this.$store.state.plat.name;
    },
    plat() {
      return this.$store.state.plat_id;
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      if(this.plat == 373){
        let res = await this.$request.trainHotList();

        this.hotList = res.data;
      }else{
        let res = await this.$request.jiazhangHotList();

        this.hotList = res.data;
      }
      console.log(this.hotList)
    },
    jumpDetail(item) {
      this.$until.toPageOpen(`/${this.$route.params.plat_type}/detail?id=${item.id}`);
      // if(this.plat == '373')this.$until.toPageOpen(`/${this.$route.params.plat_type}/detail?id=${item.id}`);
      // if(this.plat == '379')this.$until.toPageOpen(`/${this.$route.params.plat_type}/detail2?id=${item.id}`);
    },
    jumpBook(item) {
      window.open(item.url);
    },
  },
};
</script>

<style scoped lang="scss">
.two-elipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    white-space: normal;
}
.hot-title {
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  display: block;
  padding-bottom: 10px;
  img {
    margin-right: 10px;
  }
}
.classify {
  width: 360px;
  margin-bottom: 50px;

  .hot-item {
    width: 100%;
    
    padding: 10px 30px 10px 0;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    background: url("https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/line2.jpg")
      bottom no-repeat;
    .tip {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 1;
      img {
        width: 40px;
      }
    }
    &:hover {
      background: #f8f8f8;
    }

    .cover {
      width: 100%;
      overflow: hidden;
      ::v-deep img {
        border-radius: 6px;
      }
    }
    .cover2 {
      flex: 0 0 76px;
      height: 106px;
      margin-right: 10px;

      overflow: hidden;
      ::v-deep img {
        border-radius: 6px;
      }
    }

    .detail {
      flex: auto;
      .price {
        color: #ff6c00;
        font-size: 12px;
        b {
          font-size: 24px;
          margin-right: 8px;
        }
        span {
          color: #7f7f7f;
          display: block;
          text-decoration: line-through;
          display: inline;
          display: none;
        }
      }
      .title {
        font-size: 16px;
        font-weight: 500;
      }

      .subtitle {
        font-size: 14px;
        color: #666;
      }
    }
    .detail2 {
      display: flex;
      flex-direction: column;
      height: 100%;
      .subtitle {
        flex: 1;
      }
    }
  }
}
</style>
